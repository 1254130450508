var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner ml-0"},[_c('PricingPlans'),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"Login-card-title font-weight-bold mb-3 d-flex align-items-center justify-content-center",attrs:{"title-tag":"h1"}},[_vm._v(" "+_vm._s(_vm.$t('Login.Title'))+" ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Login.Subtitle'))+" ")]),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{staticClass:"font-weight-bold",attrs:{"label":"Email","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"john@example.com","autocomplete":"username"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"font-weight-bold d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v(" "+_vm._s(_vm.$t('Login.PasswordLabel'))+" ")]),_c('b-link',{attrs:{"to":{
                    name: 'auth-forgot',
                    params: { lang: this.lang || undefined },
                  }}},[_c('small',[_vm._v(_vm._s(_vm.$t('Login.ForgotPassword')))])])],1),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············","autocomplete":"current-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t('Login.RememberMe'))+" ")])],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":_vm.isLoading},on:{"click":_vm.validationForm}},[_vm._v(" "+_vm._s(_vm.$t('Login.LoginBtn'))+" ")])],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('Login.NewOnPlatform')))]),_c('b-link',{attrs:{"to":{
              name: 'auth-register',
              params: { lang: _vm.lang || undefined },
            }},on:{"click":function($event){return _vm.tracking('SIGNUP', {})}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Login.CreateAccountBtn')))])])],1),_c('footer',{staticClass:"page-footer font-small blue mt-4 pt-3"},[_c('div',{staticClass:"col-md-12 col-lg-12 d-flex justify-content-center"},[_c('div',[_c('h3',{staticClass:"free d-flex justify-content-center"},[_c('b-img',{staticClass:"Support mr-2",attrs:{"src":require("@/assets/images/illustration/Support.svg"),"alt":"basic svg img"}})],1),_c('span',{staticClass:"fill-filer-color"},[_vm._v("support@coinrex.in")])])]),_c('div',{staticClass:"col-md-12 col-lg-12 d-flex justify-content-center pt-1"},[_c('div',[_c('p',{staticClass:"copyright"},[_vm._v(" Copyright ©"+_vm._s(new Date().getFullYear())+" "+_vm._s(_vm.$t('Login.AllRightsReserved'))+" ")])])])])],1)],1)],1),_c('Loader',{attrs:{"show":_vm.isLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }